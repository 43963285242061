import { get, post } from '@/api/index'
const key = { key: 'SK5BZ-TMMC4-6ZQU2-XFW4Y-46423-WVBVH' }

export const getRimAddress = params =>
  get('/tmap/ws/place/v1/suggestion', { ...key, ...params })

/**
 *
 */
export const getIpAddress = params =>
  get('/tmap/ws/location/v1/ip', { ...key, ...params })

/**
 * 获取当前租户的自定义字段列表
 */
export const getAutoField = params =>
  post('/dapi/fellow-customer-management/v1/custom/custom_enable', params)

/**
 * 修改自定义字段
 */
export const editAutoField = params =>
  post('/dapi/fellow-customer-management/v1/custom_user/add', params)

/**
 * 获取群列表
 */
export const getGroupList = params =>
  post('/dapi/fellow-customer-management/v1/chat_group/list', params)

// 获取客户详情
export const getUserDetail = params =>
  get('/dapi/user-profile/v1/user/detail', params)

// 获取客户详情
export const updateUser = params =>
  post('/dapi/user-profile/v1/user/update', params)

// 获取客户详情
export const getDealers = params =>
  post('/dapi/iov-common-data-service/v1/dealer/get_by_geo', params)

// 获取客户详情
export const getUserCustomField = params =>
  get('/dapi/user-profile/v1/user/custom/list', params)

// 自定义用户字段分组
export const getCustomGroupList = params =>
  get('/dapi/user-profile/v1/user/custom/group/list', params)

// 查询单个用户的轨迹
export const getUserTrail = params =>
  post('/dapi/user-trail/v1/user/trail/get', params)

// 查询单个用户的轨迹
export const addUserTrail = params =>
  post('/dapi/user-trail/v1/user/trail/add', params)
