<template>
  <van-popup
    v-model="showPopup"
    position="bottom"
    class="popup-style"
    get-container="#app"
    @close="onCancel"
    @closed="onCancel"
  >
    <div class="container-box">
      <div class="popup-header">
        <span class="cancel" @click="onCancel">取消</span>
        <span class="title">{{ fieldModel.customName }}</span>
        <span class="submit" @click="onSubmit">确认</span>
      </div>
      <div class="popup-container">
        <van-radio-group v-model="fieldModel.customValue">
          <van-cell-group inset>
            <van-cell clickable @click="fieldModel.customValue = true">
              <template #title>
                <div class="flex">
                  <van-radio :name="true" /> <span class="radio-title">是</span>
                </div>
              </template>
            </van-cell>
            <van-cell clickable @click="fieldModel.customValue = false">
              <template #title>
                <div class="flex">
                  <van-radio :name="false" /> <span class="radio-title">否</span>
                </div>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
    </div>
  </van-popup>
</template>

<script>
import fieldMixin from '../mixins'
export default {
  mixins: [fieldMixin],

  created() {
    this.fieldModel.customValue = ['true', 'false'].includes(this.fieldModel.customValue) ? this.fieldModel.customValue === 'true' : ''
  },

  methods: {
    onSubmit() {
      if (this.fieldModel.isRequired === 1 && ![true, false].includes(this.fieldModel.customValue)) {
        return this.$toast(`请选择${this.fieldModel.customName}`)
      }
      this.onUpdate()
    }
  }
}
</script>

<style lang="less" scoped>
.popup-style {
  .flex {
    display: flex;
    .radio-title {
      margin-left: 16px;
    }
  }
  .container-box {
    position: relative;
    width: 100%;
    height: 100%;
    .popup-header {
      line-height: 50px;
      width: 100%;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 14px;
      font-size: 14px;
      .cancel {
        color: #5f667a;
      }
      .title {
        color: #262c37;
        font-weight: bold;
      }
      .submit {
        color: #3173cd;
      }
    }
    .popup-container {
      padding: 16px 0;
    }
  }
}
</style>
