<template>
  <van-field
    ref="vanField"
    v-model.trim="fieldModel.customValue"
    placeholder="请输入"
    :maxlength="fieldModel.length"
    :show-word-limit="showWordLimit"
    :readonly="readonly"
    @focus="showWordLimit=true"
    @blur="showWordLimit = false"
    @change="onSubmit"
  />
</template>

<script>
import fieldMixin from '../mixins'
export default {
  mixins: [fieldMixin],
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showWordLimit: false
    }
  },
  watch: {
    fieldData: {
      immediate: true,
      deep: true,
      handler() {
        this.fieldModel = {
          ...this.fieldData,
          customValue:
            this.fieldData.customValue === null
              ? ''
              : this.fieldData.customValue
        }
      }
    }
  },
  methods: {
    onSubmit() {
      if (this.fieldModel.isRequired === 1 && !this.fieldModel.customValue) {
        this.fieldModel.customValue = this.fieldData.customValue === null
          ? ''
          : this.fieldData.customValue
        return this.$toast(`${this.fieldModel.customName}不能为空`)
      }
      this.onUpdate()
    }
  }
}
</script>

<style lang="less" scoped>
.van-field {
  padding: 0;
  height: 27px;
  /deep/ .van-field__control {
    width: calc(100% - 35px);
  }
  /deep/ .van-field__word-limit {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
