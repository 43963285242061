<template>
  <div class="customer-card-index">
    <index-page v-if="inited" />
  </div>
</template>

<script>
import './constant'
import { mapGetters } from 'vuex'
import wxSDK from '@/scripts/wxsdk'
import store from '@/store/index'
import IndexPage from './components/index'

export default {
  name: 'CustomerCard',
  components: {
    IndexPage
  },
  data() {
    return {
      inited: false
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userAuth/getUsersCurrentInfo'
    })
  },

  async created() {
    await this.init()
  },

  methods: {
    async init() {
      // this.inited = true
      await wxSDK.config(
        [],
        [
          'getContext',
          'getCurExternalContact'
        ]
      )
      const customerId = await wxSDK.getCurExternalContact()
      store.commit('userAuth/customerInfo', customerId)
      console.log('customerId', customerId)
      this.inited = true
    }
  }
}
</script>

<style lang="less">
body,
#app {
 min-height: 100vh;
  overflow:visible;
}
</style>

<style lang="less" scoped>
.customer-card-index {
  height: 100vh;
}
</style>
