<template>
  <van-popup
    v-model="showPopup"
    position="bottom"
    class="popup-style"
    @close="onCancel"
    @closed="onClosed"
  >
    <div class="container-box">
      <div class="popup-header">
        <span class="cancel" @click="onCancel">取消</span>
        <span class="title">添加跟进记录</span>
        <span class="submit" @click="onSubmit">确认</span>
      </div>
      <div class="popup-container">
        <van-field
          v-model="addParams.remark"
          rows="6"
          type="textarea"
          class="record-input"
          placeholder="请输入跟进记录"
          maxlength="200"
        />
      </div>
    </div>
  </van-popup>
</template>

<script>
import { addTask } from '@/api/modules/task'
import { addUserTrail } from '@/api/modules/custom'
import { mapGetters } from 'vuex'
import { Toast } from 'vant'
export default {
  props: {
    eventName: {
      type: String,
      default: ''
    },
    customInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showPopup: false,
      addParams: {
        createTime: Date.now(),
        eventId: 'ev_follow_add',
        eventName: '添加跟进',
        fellowId: this.$store.state.userAuth.usersCurrentInfo.name,
        fellowName: this.$store.state.userAuth.usersCurrentInfo.userName,
        remark: '',
        source: '添加跟进',
        userName: '',
        userOneId: ''
      },
      addTaskParams: {
        taskName: '跟进',
        taskDetail: '',
        endTime: '',
        taskType: 1,
        tenantId: global.APP_CONFIG.tenantId,
        ext: {
          nickName: ''
        },
        executors: [
        ],
        taskScheduleConfig: {},
        creator: {
          userId: '',
          name: ''
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      userInfo: 'userAuth/getUsersCurrentInfo'
    })
  },

  created() {
    console.log(this.customInfo)
  },

  methods: {
    onCancel() {
      this.showPopup = false
      this.$emit('on-close')
    },

    onClosed() {
      this.addParams.remark = ''
    },

    async onSubmit() {
      this.addParams.userName = this.customInfo.name
      this.addParams.userOneId = this.customInfo.oneId
      console.log(this.addParams)
      if (!this.addParams.remark) {
        this.$toast('跟进内容不能为空')
        return false
      }
      this.addTaskParams.taskDetail = this.addParams.remark
      this.addTaskParams.endTime = Date.now() + 24 * 60 * 60 * 1000
      this.addTaskParams.ext.nickName = this.customInfo.name
      this.addTaskParams.executors = [{
        userId: this.userInfo.name,
        name: this.userInfo.userName
      }]
      this.addTaskParams.creator = {
        userId: this.userInfo.name,
        name: this.userInfo.userName
      }
      const res = await addUserTrail(this.addParams)
      const res1 = await addTask(this.addTaskParams)
      if (res.code === 0 && res1.code === 0) {
        this.$toast('跟进成功')
        this.$emit('on-refresh')
        this.onCancel()
      }
      else {
        Toast(res.msg || res1.msg)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.popup-style {
  .container-box {
    position: relative;
    width: 100%;
    height: 100%;
    .popup-header {
      line-height: 50px;
      width: 100%;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 14px;
      font-size: 14px;
      position: absolute;
      top: 0;
      .cancel {
        color: #5f667a;
      }
      .title {
        color: #262c37;
        font-weight: bold;
      }
      .submit {
        color: #3173cd;
      }
    }
    .popup-container {
      padding: 59px 27px 35px 27px;
      .record-input {
        height: 120px;
        background: #f5f5f5;
        border-radius: 4px;
        padding: 10px;
      }
    }
  }
}
</style>
