<template>
  <div ref="refPage" class="customer-info">
    <div
      v-for="group in canShowGroupList"
      :key="group.id"
      ref="refGroup"
      class="info-card"
      :class="`card-${group.id}`"
    >
      <div class="card-header">{{ group.customGroupName }}</div>
      <div class="card-container">
        <div
          v-for="field in group.child"
          :key="field.id"
          class="info-item"
          @click="onOpenPopup(field)"
        >
          <span class="label">{{ field.customName }}</span>
          <div v-if="field.customFiledType === 0" class="input-value">
            <single-text-inp
              :one-id="oneId"
              :field-data="field"
              :readonly="
                ['avatar', 'createTime', 'oneId', 'addWay', 'remark'].includes(
                  field.customNameEn
                )
              "
              @on-close="onClosePopup"
            />
          </div>
          <span v-else class="value">{{ getText(field) }}</span>
        </div>
      </div>
    </div>

    <div class="floating-btn">
      <div
        v-for="(group, index) in canShowGroupList"
        :key="group.id"
        class="btn"
        :class="floatIndex === index ? 'active' : ''"
        @click="onShowElement(index)"
      >
        {{ group.customGroupName }}
      </div>
    </div>
    <component
      :is="componentName"
      :one-id="oneId"
      :field-data="fieldData"
      @on-close="onClosePopup"
    />
  </div>
</template>

<script>
import singleTextInp from './singleTextInp.vue'
import multiText from './multiText.vue'
import singleEnum from './singleEnum.vue'
import multiEnum from './multiEnum.vue'
import date from './date.vue'
import number from './number.vue'
import boolean from './boolean.vue'
import email from './email.vue'
import datetime from './datetime.vue'
import percent from './percent.vue'
import moment from 'moment'
import { debounce } from '@/utils/utils'
export default {
  components: {
    singleTextInp,
    multiText,
    singleEnum,
    multiEnum,
    date,
    number,
    boolean,
    email,
    datetime,
    percent
  },
  props: {
    groupList: {
      type: Array,
      default: () => []
    },
    oneId: {
      type: String,
      default: ''
    },
    activeName: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      floatIndex: 0,
      componentName: '',
      fieldData: null,
      indexList: [],
      positionList: []
    }
  },

  computed: {
    cmpIndexList() {
      return this.groupList.map((item) => item.customGroupName)
    },
    canShowGroupList() {
      return this.groupList.filter((g) => g.child && g.child.length)
    }
  },

  watch: {
    activeName(val) {
      if (val === 0) {
        this.onListenScroll()
        window.addEventListener(
          'scroll',
          debounce(this.onListenScroll, 200),
          true
        )
      }
      else {
        window.removeEventListener('scroll', this.onListenScroll)
      }
    }
  },

  mounted() {
    window.addEventListener('scroll', debounce(this.onListenScroll, 200), true)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onListenScroll)
  },

  methods: {
    isInViewPortOfTwo(el) {
      const viewPortHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      const top = el.getBoundingClientRect() && el.getBoundingClientRect().top
      return top <= viewPortHeight - 30
    },
    onListenScroll() {
      for (let i = this.canShowGroupList.length; i > 0; i--) {
        if (this.isInViewPortOfTwo(this.$refs.refGroup[i - 1])) {
          this.floatIndex = i - 1
          break
        }
      }
    },
    onShowElement(refName) {
      this.floatIndex = refName
      this.$refs.refGroup[refName].scrollIntoView()
    },
    onOpenPopup(field) {
      this.fieldData = field
      const disabledEdit = [
        'avatar',
        'createTime',
        'oneId',
        'addWay',
        'remark'
      ]
      if (disabledEdit.includes(field.customNameEn)) {
        return
      }
      let componentName = ''
      switch (field.customFiledType) {
        // case 0:
        //   componentName = 'singleText'
        //   break
        case 1:
          componentName = 'multiText'
          break
        case 2:
          componentName = 'singleEnum'
          break
        case 3:
          componentName = 'multiEnum'
          break
        case 4:
          componentName = 'date'
          break
        case 5:
          componentName = 'number'
          break
        case 6:
          componentName = 'boolean'
          break
        case 7:
          componentName = 'email'
          break
        case 8:
          componentName = 'percent'
          break
        case 9:
          componentName = 'datetime'
          break
      }
      this.componentName = componentName
    },

    // 关闭字段编辑
    onClosePopup(ifRefresh = false) {
      this.componentName = ''
      if (ifRefresh) {
        this.$emit('on-refresh')
      }
    },
    getText(item) {
      if (item.customFiledType === 4) {
        return item.customValue
          ? moment(Number(item.customValue)).format('YYYY-MM-DD')
          : ''
      }
      else if (item.customFiledType === 9) {
        return item.customValue
          ? moment(Number(item.customValue)).format('YYYY-MM-DD HH:mm:ss')
          : ''
      }
      else if (item.customFiledType === 6) {
        return ['true', 'false'].includes(item.customValue)
          ? item.customValue === 'true'
            ? '是'
            : '否'
          : ''
      }
      else if (item.customFiledType === 8) {
        return item.customValue ? item.customValue + '%' : ''
      }
      else {
        return item.customValue
      }
    }
  }
}
</script>

<style lang="less" scoped>
.customer-info {
  position: relative;
  /deep/ .van-index-bar {
    .card-header {
      .van-index-anchor {
        font-size: unset;
        padding: unset;
        line-height: unset;
      }
    }
    .van-index-bar__sidebar {
      right: 23px;
      .van-index-bar__index {
        padding: 0 8px;
        &.van-index-bar__index--active {
          background: #4873c0;
          color: #fff;
        }
        text-align: center;
        background-color: #fff;
        height: 24px;
        line-height: 24px;

        box-shadow: 0 4px 6px 0 rgba(171, 179, 196, 0.4);
        border-radius: 12px;
        font-size: 10px;
        color: #666;
        letter-spacing: 0;
        margin-bottom: 10px;
      }
    }
  }
  .info-card {
    background-color: #fff;
    margin: 10px 0;

    .card-header {
      box-sizing: border-box;
      line-height: 52px;
      padding-left: 26px;
      font-size: 16px;
      color: #333333;
      position: relative;
      border-bottom: 1px solid #f5f5f5;
      &::before {
        content: "";
        position: absolute;
        background-color: #6085c8;
        height: 16px;
        width: 2px;
        left: 16px;
        top: 18px;
      }
    }

    .card-container {
      box-sizing: border-box;
      padding: 0 16px;

      .info-item {
        display: flex;
        min-height: 48px;
        box-sizing: border-box;
        border-bottom: 1px solid #f5f5f5;
        align-items: center;
        padding: 10px 0;

        .label {
          display: inline-block;
          width: 99px;
          font-size: 13px;
          color: #98999b;
          flex: 0 0 99px;
        }
        .value {
          font-size: 14px;
          color: #333333;
          line-height: 25px;
        }
      }
    }
  }

  .floating-btn {
    position: fixed;
    top: 240px;
    right: 23px;
    .btn {
      padding: 0 8px;
      &.active {
        background: #4873c0;
        color: #fff;
      }
      text-align: center;
      background-color: #fff;
      height: 24px;
      line-height: 24px;
      box-shadow: 0 4px 6px 0 rgba(171, 179, 196, 0.4);
      border-radius: 12px;
      font-size: 10px;
      color: #666;
      letter-spacing: 0;
      margin-bottom: 10px;
    }
  }
  .input-value {
    width: 100%;
    position: relative;
  }
}
</style>
