<template>
  <div class="customer-card">
    <div class="card-header">
      <div class="header-bg"></div>
      <div class="info-box">
        <van-image
          round
          width="81px"
          height="81px"
          fit="cover"
          :src="customInfo.avatar"
        />
        <div class="user-info">
          <div class="nickname">
            {{ customInfo.name }}
            <img
              v-if="customInfo.gender === '2'"
              :src="IconFemale"
              class="sex"
            />
            <img v-if="customInfo.gender === '1'" :src="IconMale" class="sex" />
          </div>
          <div v-show="customInfo.phone" class="phone">
            <img
              src="../../../assets/img/customer/icon_phone.png"
              class="icon-phone"
            />
            {{ customInfo.phone }}
          </div>
        </div>
      </div>
    </div>

    <div class="tab-box">
      <van-tabs v-model="activeName">
        <van-tab title="客户信息">
          <customer-info :active-name="activeName" :one-id="oneId" :group-list="groupList" @on-refresh="fetchUserDetail" />
        </van-tab>
        <van-tab title="客户动态">
          <customer-track
            ref="refTrack"
            :user-name="customInfo.name"
            :one-id="oneId"
          />
        </van-tab>
      </van-tabs>
    </div>

    <div class="floating-btn" @click="onOpenAddRecord">
      <img :src="IconPlus" />
    </div>

    <add-record
      v-show="showFloating"
      ref="refAddRecord"
      :custom-info="customInfo"
      @on-close="onCloseAddRecord"
      @on-refresh="onRefresh"
    />
  </div>
</template>

<script>
import '../constant'
import CustomerInfo from './customerInfo.vue'
import CustomerTrack from './customerTrack.vue'
import AddRecord from './addRecord.vue'
import IconMale from '../../../assets/img/customer/icon_male.png'
import IconFemale from '../../../assets/img/customer/icon_female.png'
import IconPlus from '../../../assets/img/customer/icon_plus.png'
import store from '@/store/index'
import moment from 'moment'
import { getUserDetail, getCustomGroupList } from '@/api/modules/custom'
export default {
  components: {
    CustomerInfo,
    CustomerTrack,
    AddRecord
  },
  data() {
    return {
      IconMale,
      IconFemale,
      IconPlus,
      activeName: 0,
      showFloating: true,
      inited: false,
      groupList: [],
      customInfo: {
        name: '',
        avatar: '',
        phone: '',
        gender: '',
        oneId: ''
      },
      oneId: ''
    }
  },
  created() {
    this.fetchUserDetail()
  },

  methods: {
    onRefresh() {
      this.$refs.refTrack && this.$refs.refTrack.fetchUserTrail()
    },

    onCloseAddRecord() {
      this.showFloating = false
    },
    onOpenAddRecord() {
      this.$refs.refAddRecord.showPopup = true
    },

    getAddWayText(val) {
      switch (val) {
        case '10001':
          return '企微添加'
        default:
          return ''
      }
    },
    async fetchUserDetail() {
      // const externalId = 'wmtw8EEQAADIfBVpFYpobARhnIFmyRaw' // 测试
      // const externalId = 'wmtw8EEQAAXfvYVFRAMSho0xLrYCrZlQ'
      // const externalId = 'wmtw8EEQAAHW3ih74Shf4j0EGq3Ogz2g'
      const externalId = store.state.userAuth.customerInfo
      console.log('store.state.userAuth.customerInfo', store.state.userAuth)
      const groupRes = await getCustomGroupList({
        tenantId: global.APP_CONFIG.tenantId
      })
      if (groupRes.code === 0) {
        if (groupRes.data.length > 0) {
          const groupList = []
          groupList.push(...groupRes.data)
          const res = await getUserDetail({ externalId })
          if (res.code === 0) {
            // await getDealers({ latitude: '', longitude: '' })
            const oList = res.data.sort((a, b) => a.sort - b.sort)
            const list = []
            oList.forEach(item => {
              if (
                ['name', 'avatar', 'phone', 'gender'].includes(
                  item.customNameEn
                )
              ) {
                this.customInfo[item.customNameEn] = item.customValue
              }
              else {
                if (item.customNameEn === 'oneId') {
                  this.oneId = item.customValue
                  this.customInfo.oneId = item.customValue
                }
                if (item.customNameEn === 'createTime' && item.customValue) {
                  item.customValue = moment(Number(item.customValue)).format(
                    'YYYY-MM-DD HH:mm:ss'
                  )
                }

                if (item.customNameEn === 'addWay' && item.customValue) {
                  item.customValue = this.getAddWayText(item.customValue)
                }
                list.push(item)
              }
            })
            groupList.forEach(item => {
              item.child = list.filter(k => k.customGroup === item.id)
            })
          }
          this.groupList = groupList
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.customer-card {
  height: 100vh;
  .card-header {
    position: relative;
    height: 170px;
    box-sizing: border-box;
    background-color: #fff;
    .header-bg {
      width: 100%;
      height: 108px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #4873c0;
    }
    .info-box {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding-left: 25px;
      position: relative;
      z-index: 1;
      background: url('../../../assets/img/customer/card_bg.png') no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;

      .user-info {
        box-sizing: border-box;
        padding: 12px 8px;
        height: 81px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .nickname {
          font-size: 18px;
          color: #333;
          font-weight: bold;
          .sex {
            width: 12px;
          }
        }
        .phone {
          font-size: 14px;
          color: #333;
          display: flex;
          align-items: center;
          .icon-phone {
            width: 14px;
          }
        }
      }
    }
  }

  .tab-box {
    /deep/ .van-tabs {
      .van-tabs__nav {
        .van-tab {
          flex: none;
          margin-left: 20px;
        }
        .van-tabs__line {
          width: 23px;
          height: 3px;
          background-color: #4873c0;
          margin-bottom: 3px;
        }
      }
    }
  }

  .floating-btn {
    position: fixed;
    right: 23px;
    bottom: 23px;
    width: 50px;
    height: 50px;
    img {
      width: 100%;
    }
  }
}
</style>
