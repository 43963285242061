<template>
  <van-popup
    v-model="showPopup"
    position="bottom"
    class="popup-style"
    get-container="#app"
    @close="onCancel"
    @closed="onCancel"
  >
    <div class="container-box">
      <div class="popup-header">
        <span class="cancel" @click="onCancel">取消</span>
        <span class="title">{{ fieldModel.customName }}</span>
        <span class="submit" @click="onSubmit">确认</span>
      </div>
      <div class="popup-container">
        <van-datetime-picker
          v-model="fieldModel.customValue"
          :visible-item-count="5"
          :show-toolbar="false"
          type="datetime"
        />
      </div>
    </div>
  </van-popup>
</template>

<script>
import fieldMixin from '../mixins'
import moment from 'moment'
export default {
  mixins: [fieldMixin],

  created() {
    if (this.fieldModel.customValue) {
      this.fieldModel.customValue = moment(
        Number(this.fieldModel.customValue)
      ).toDate()
    }
    else {
      this.fieldModel.customValue = new Date()
    }
  },

  methods: {
    onSubmit() {
      if (this.fieldModel.isRequired === 1) {
        if (!this.fieldModel.customValue) {
          return this.$toast(`${this.fieldModel.customName}必须选择`)
        }
      }

      if (this.fieldModel.customValue) {
        this.fieldModel.customValue = moment(
          this.fieldModel.customValue
        ).valueOf()
      }
      this.onUpdate()
    }
  }
}
</script>

<style lang="less" scoped>
.popup-style {
  .container-box {
    position: relative;
    width: 100%;
    height: 100%;
    .popup-header {
      line-height: 50px;
      width: 100%;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 14px;
      font-size: 14px;
      .cancel {
        color: #5f667a;
      }
      .title {
        color: #262c37;
        font-weight: bold;
      }
      .submit {
        color: #3173cd;
      }
    }
    .popup-container {
      padding: 0 27px 35px 27px;
      .record-input {
        height: 120px;
        background: #f5f5f5;
        border-radius: 4px;
        padding: 10px;
      }
    }
  }
}
</style>
