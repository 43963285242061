<template>
  <van-popup v-model="showPopup" position="bottom" class="popup-style">
    <div class="container-box">
      <div class="popup-header">
        <div class="cancel" @click="onCancel">取消</div>
        <div class="title">选择事件</div>
        <div class="submit" @click="onSubmit">确认</div>
      </div>
      <div class="event-list">
        <div class="event-item" :class="{ active: event === '' }" @click="onSelected('')">
          全部事件
        </div>
        <div class="event-item" :class="{ active: event === 'ev_friend_add' }" @click="onSelected('ev_friend_add')">添加好友</div>
        <div class="event-item" :class="{ active: event === 'ev_friend_del_fellow' }" @click="onSelected('ev_friend_del_fellow')">客户流失</div>
        <div class="event-item" :class="{ active: event === 'ev_group_add' }" @click="onSelected('ev_group_add')">加入群聊</div>
        <div class="event-item" :class="{ active: event === 'ev_group_quit' }" @click="onSelected('ev_group_quit')">退出群聊</div>
        <div class="event-item" :class="{ active: event === 'ev_follow_add' }" @click="onSelected('ev_follow_add')">跟进记录</div>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    eventName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showPopup: false,
      event: ''
    }
  },

  watch: {
    showPopup(val) {
      if (val) {
        this.event = this.eventName
      }
    }
  },

  methods: {
    onCancel() {
      this.showPopup = false
    },

    onSubmit() {
      this.$emit('on-change', this.event)
    },

    onSelected(eventName) {
      this.event = eventName
    }
  }
}
</script>

<style lang="less" scoped>
.popup-style {
  max-height: 410px;
  .container-box {
    position: relative;
    width: 100%;
    height: 100%;
    .popup-header {
      line-height: 50px;
      width: 100%;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 14px;
      font-size: 14px;
      .cancel {
        color: #5f667a;
        cursor: pointer;
        display: block;
        padding: 0 10px;
      }
      .title {
        color: #262c37;
        font-weight: bold;
      }
      .submit {
        color: #3173cd;
        cursor: pointer;
        display: block;
        padding: 0 10px;
      }
    }
    .event-list {
      max-height: 410px;
      overflow-y: auto;

      .event-item {
        cursor: pointer;
        &.active {
          color: #3173cd;
        }
        line-height: 52px;
        text-align: center;
        font-size: 16px;
        color: #333333;
      }
    }
  }
}
</style>
