<template>
  <div class="customer-track">
    <div class="track-header">
      <div class="filter-btn" @click="onOpenEventSheet">
        <img
          class="icon-filter"
          src="../../../assets/img/customer/icon_filter.png"
        />
        筛选事件
      </div>
    </div>
    <div class="track-list">
      <div v-for="(item, index) in cmpList" :key="index" class="track-group">
        <div class="group-title">
          <span class="date">{{ item.year }}</span>
          <span class="week">星期{{ item.week | fltWeek }}</span>
        </div>
        <div class="group-content">
          <div v-for="(event, i) in item.list" :key="i" class="track-item">
            <div class="event-time">{{ event.hour }}</div>
            <div class="track-info">
              <div class="track-name">#{{ event.eventName }}</div>

              <div
                v-if="event.eventId === 'ev_follow_add'"
                class="track-describe"
              >
                跟进人：{{ event.fellowName }} <br />
                跟进内容：{{ event.remark }}
              </div>
              <div v-else class="track-describe">
                {{ getEventText(event) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <event-sheet
      ref="refEventSheet"
      :event-name="eventName"
      @on-change="onChangeEvent"
    />
  </div>
</template>

<script>
import EventSheet from './eventSheet.vue'
import { getUserTrail } from '@/api/modules/custom'
import moment from 'moment'

export default {
  components: {
    EventSheet
  },
  filters: {
    fltWeek(val) {
      const text = ['日', '一', '二', '三', '四', '五', '六', '日']
      return text[val]
    }
  },
  props: {
    oneId: {
      type: String,
      default: ''
    },
    userName: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      eventName: '',
      eventList: []
    }
  },

  computed: {
    cmpList() {
      const fltList = this.eventName
        ? this.eventList.filter(item => item.eventId === this.eventName)
        : this.eventList
      const eventList = fltList.map(item => {
        item.year = moment(item.createTime).format('YYYY年M月D日')
        item.week = moment(item.createTime).format('e')
        item.hour = moment(item.createTime).format('H:mm')
        return item
      })
      let dateList = eventList.map(item => item.year)
      dateList = [...new Set(dateList)]
      return dateList.map(item => {
        console.log(item)
        const list = eventList.filter(k => k.year === item)
        console.log('list', list)
        const obj = {
          year: item,
          week: list[0].week,
          list
        }
        return obj
      })
    }
  },

  created() {
    this.fetchUserTrail()
  },

  methods: {
    // 跟进记录文本
    getEventText(event) {
      switch (event.eventId) {
        case 'ev_friend_add':
          return `顾问 ${event.fellowName} 添加客户 ${this.userName} 为好友`
        case 'ev_friend_add_cust':
          return `客户 ${event.userName} 添加顾问 ${this.event.fellowName} 为好友`
        case 'ev_friend_del_fellow':
          return `顾问 ${event.fellowName} 将客户 ${event.userName} 从好友中删除`
        case 'ev_friend_del_cust':
          return `顾问 ${event.fellowName} 被客户 ${event.userName} 删除`
        case 'ev_group_add':
          return `${event.remark}`
        case 'ev_group_quit':
          return `${event.remark}`
        case 'ev_follow_add':
          return ``
      }
    },

    onChangeEvent(eventName) {
      this.eventName = eventName
      this.$refs.refEventSheet.showPopup = false
    },
    // 打开事件筛选
    onOpenEventSheet() {
      this.$refs.refEventSheet.showPopup = true
    },

    async fetchUserTrail() {
      const res = await getUserTrail({ userOneId: this.oneId })
      if (res.code === 0) {
        this.eventList = res.data
      }
    }
  }
}
</script>

<style lang="less" scoped>
.customer-track {
  background-color: #fff;
  margin-top: 10px;
  .track-header {
    height: 52px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 26px;
    .filter-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 89px;
      height: 27px;
      background: #3173cd;
      border-radius: 15px;
      font-size: 12px;
      color: #ffffff;
      .icon-filter {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }

  .track-list {
    .track-group {
      border-top: 1px solid #f5f5f5;
      .group-title {
        padding: 12px 24px;
        font-size: 14px;
        font-weight: bold;
        border-bottom: 1px solid #f5f5f5;
        .date {
          color: #4873c0;
        }
        .week {
          color: #333;
        }
      }
      .group-content {
        padding: 0 16px;
        .track-item {
          display: flex;
          padding: 12px 0;
          border-bottom: 1px solid #f5f5f5;
          &:last-child {
            border-width: 0;
          }
          .event-time {
            flex: 0 0 66px;
            font-size: 13px;
            color: #98999b;
            font-weight: bold;
            padding-left: 10px;
            box-sizing: border-box;
          }
          .track-info {
            border-left: 2px solid #4873c0;
            box-sizing: border-box;
            padding-left: 20px;
            .track-name {
              font-size: 14px;
              font-weight: bold;
              color: #333333;
            }
            .track-describe {
              font-size: 12px;
              line-height: 24px;
              color: #333333;
              word-break : break-all
            }
          }
        }
      }
    }
  }
}
</style>
