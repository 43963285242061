import { updateUser } from '@/api/modules/custom'

const fieldMixin = {
  props: {
    fieldData: {
      type: Object,
      required: true
    },
    oneId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showPopup: false,
      fieldModel: {
        customFiledType: '',
        customGroup: '',
        customName: '',
        customNameEn: '',
        customType: '',
        customValue: '',
        customValueId: '',
        id: '',
        isActive: '',
        isRequired: '',
        length: '',
        sort: '',
        status: '',
        tenantId: ''
      }
    }
  },

  created() {
    this.fieldModel = { ...this.fieldData, customValue: !this.fieldData.customValue ? '' : this.fieldData.customValue }
    console.log(this.fieldModel.customValue)
    this.showPopup = true
  },

  methods: {
    onFocus() {
      setTimeout(() => {
        this.$refs.textarea.scrollIntoView(true)
      }, 500)
    },
    async onUpdate() {
      if (Array.isArray(this.fieldModel.customValue)) {
        this.fieldModel.customValue = this.fieldModel.customValue.join()
      }
      else if (this.fieldModel.customValue === null) {
        this.fieldModel.customValue = ''
      }
      const params = {
        oneId: this.oneId,
        customEnableVoList: [this.fieldModel],
        tenantId: global.APP_CONFIG.tenantId
      }
      console.log(params)
      const res = await updateUser(params)
      if (res.code === 0) {
        this.$toast('操作成功')
        this.$emit('on-close', true)
      }
      else {
        this.$toast(res.msg)
      }
    },

    onCancel() {
      this.$emit('on-close')
    }
  }
}

export default fieldMixin
