<template>
  <van-popup
    v-model="showPopup"
    position="bottom"
    class="popup-style"
    get-container="#app"
    @close="onCancel"
    @closed="onCancel"
  >
    <div class="container-box">
      <div class="popup-header">
        <span class="cancel" @click="onCancel">取消</span>
        <span class="title">{{ fieldModel.customName }}</span>
        <span class="submit" @click="onSubmit">确认</span>
      </div>
      <div class="popup-container">
        <van-radio-group v-model="fieldModel.customValue">
          <van-cell-group inset>
            <van-cell v-for="item in enumList" :key="item" clickable @click="fieldModel.customValue = item">
              <template #title>
                <div class="flex">
                  <van-radio :name="item" /> <span class="radio-title">{{item}}</span>
                </div>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
    </div>
  </van-popup>
</template>

<script>
import fieldMixin from '../mixins'
import { getUserCustomField } from '@/api/modules/custom'
export default {
  mixins: [fieldMixin],

  data() {
    return {
      enumList: []
    }
  },

  created() {
    this.fetchEnum()
  },

  methods: {
    async fetchEnum() {
      const res = await getUserCustomField({
        customName: this.fieldModel.customName,
        tenantId: global.APP_CONFIG.tenantId
      })
      if (res.code === 0 && Array.isArray(res.data) && res.data.length > 0) {
        this.enumList = res.data[0].customValue.split(',')
      }
    },

    onSubmit() {
      if (this.fieldModel.isRequired === 1 && !this.fieldModel.customValue) {
        return this.$toast(`${this.fieldModel.customName}不能为空`)
      }
      this.onUpdate()
    }
  }
}
</script>

<style lang="less" scoped>
.popup-style {
  max-height: 440px;
  .flex {
    display: flex;
    .radio-title {
      margin-left: 16px;
    }
  }
  .container-box {
    position: relative;
    width: 100%;
    height: 100%;
    .popup-header {
      line-height: 50px;
      width: 100%;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 14px;
      font-size: 14px;
      position: absolute;
      top: 0;
      z-index: 3;
      .cancel {
        color: #5f667a;
      }
      .title {
        color: #262c37;
        font-weight: bold;
      }
      .submit {
        color: #3173cd;
      }
    }
    .popup-container {
      padding: 50px 16px 20px 16px;
      max-height: 390px;
      overflow-y: auto;
    }
  }
}
</style>
